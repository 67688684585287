.ce-embed-modal__body--open {
  overflow: hidden;
}

.ce-embed-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
}

.ce-embed-modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 995;
  background: rgba(0, 0, 0, 0.5);
}

.ce-embed-modal__content {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  overflow: auto;
  background: #fff;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 4px;
  max-width: 600px;
  width: 90%;
}

.ce-embed-modal__close-btn {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  font-size: 64px;
  opacity: 0.5;
  background: none;
  border: none;
  color: #fff;
  transition: opacity 0.2s ease;
}

.ce-embed-modal__close-btn:hover {
  opacity: 0.9;
}

@media (min-width: 768px) {
  .ce-embed-modal__content {
    min-width: 600px;
  }
}
